import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1659055c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _7569d242 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _dcbbc35c = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _22adf34a = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _2f09ee2e = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _01c58108 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _5ac61208 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _15e6e71f = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _e83ad150 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _1ad2b948 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _ce7960b8 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _6d13392d = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _5b49c169 = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _a9e83cae = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _269d4ee1 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _410acceb = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _7665c6bd = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _62093078 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _64edaeb2 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _520855ea = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _4fe2d10c = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _487d4544 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _652f4fde = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _f74a75c4 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _fa37281c = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _f5a17c46 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _4edd92da = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _22bfaadb = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _043df979 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _5d58cf1d = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _c935ec92 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _0117b4fb = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _442777b4 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _a88767f0 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _1df0bce1 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _ca6bd3f2 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _5a3b23f0 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _0cdb0f52 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _3ad60701 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _5f8b544d = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _91976e4a = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _450de8c4 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _02b7314a = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _4089d7c4 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _9817d678 = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _689003c8 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _9b98b97e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0dcfefce = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1659055c,
    children: [{
      path: "",
      component: _7569d242,
      name: "account___nl"
    }, {
      path: "details",
      component: _dcbbc35c,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _22adf34a,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _2f09ee2e,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _01c58108,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _5ac61208,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _15e6e71f,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _e83ad150,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _1ad2b948,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _ce7960b8,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _6d13392d,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _5b49c169,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _a9e83cae,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _269d4ee1,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _410acceb,
    name: "product-lister-actie___nl"
  }, {
    path: "/blog",
    component: _7665c6bd,
    name: "blog-category___nl"
  }, {
    path: "/cart",
    component: _62093078,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _64edaeb2,
    children: [{
      path: "",
      component: _520855ea,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _4fe2d10c,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _487d4544,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _652f4fde,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _f74a75c4,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _fa37281c,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _f5a17c46,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _4edd92da,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _22bfaadb,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _043df979,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _5d58cf1d,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _c935ec92,
    name: "forgot-password___nl"
  }, {
    path: "/blocks/demo",
    component: _0117b4fb,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _442777b4,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _a88767f0,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _1df0bce1,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _ca6bd3f2,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/blog/detail/:slug?",
    component: _5a3b23f0,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _0cdb0f52,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _3ad60701,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _5f8b544d,
    name: "product-detail-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _91976e4a,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _450de8c4,
    name: "search-slug___nl"
  }, {
    path: "/blog/*",
    component: _02b7314a,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _4089d7c4,
    name: "blog-tag-all___nl"
  }, {
    path: "/merk/*",
    component: _9817d678,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _689003c8,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _9b98b97e,
    name: "index___nl"
  }, {
    path: "/*",
    component: _0dcfefce,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
